.login-title {
  font-size: calc(1.375rem + 1.5vw);
  text-align: center;
  padding-top: 3rem;
}

#easyText {
  color: var(--easy-primary);
}

#bgLogin {
  background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(34, 34, 34, 1) 0%, rgba(218, 165, 32, 1) 90%);
  height: auto;
  padding-bottom: 200px;
}

#boxLogin {
  background-color: #ffffffb7;
  max-width: 500px;
  border-right: solid #e4e4e4 1px;
}

#boxLogin:nth-child(2) {
  background-color: #ffffffb7;
  max-width: 500px;
  border: none;
}


.tabs {
  position: relative;
  display: flex;
  min-height: 400px;
  overflow: hidden;
}


.tabby-tab {
  flex: 1;
}

.recover-password {
  cursor: pointer;
}

.tabby-content {
  position: absolute;

  left: 0;
  bottom: 0;
  right: 0;
  /* clear the tab labels */
  top: 40px;

  padding: 20px;
  /* border-radius: 0 0 8px 8px; */
  /* background: #553285; */

  transition:
    opacity 0.8s ease,
    transform 0.8s ease;

  /* show/hide */
  opacity: 0;
  transform: scale(0.1);
  transform-origin: top left;

}

.tabby-tab [type=radio] {
  display: none;
}

[type=radio]:checked~label {
  /* background: #553285; */
  z-index: 2;
}

[type=radio]:checked~label~.tabby-content {
  z-index: 1;

  /* show/hide */
  opacity: 1;
  transform: scale(1);
}

.term {
  color: var(--easy-primary) !important;
}

.bi-check-circle {
  margin-right: 8px;
}

.span-error {
  font-size: 0.8rem;
  color: var(--easy-error) !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .login-title {
    font-size: calc(1.2rem + 1.5vw);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}