.nav div.main_list ul li a {
    text-decoration: none;
    line-height: 65px;
    font-weight: 500;
}

.nav div.main_list ul li a:hover {
    color: var(--easy-primary);
}

.btn-login {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.btn-login i,
.btn-login span {
    color: var(--easy-secondary);
}

.btn-login i {
    font-size: 1.2rem !important;
}

.btn-login span {
    font-size: 0.9rem !important;
    margin-top: 2px;
}

.btn-login i:hover,
.btn-login i:focus,
.btn-login i:active,
.btn-login span:hover,
.btn-login span:focus,
.btn-login span:active {
    color: var(--easy-primary);
}