.container-items-support {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 25px;
}

.container-items-support .accordion {
    width: 40%;
}

.content-infos-support {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);

    padding: 15px;
    border-radius: 10px;
    margin-bottom: 25px;
}

@media (max-width: 767.98px) {
    .container-items-support {
        flex-direction: column;
    }

    .container-items-support .accordion {
        width: 100%;
    }

    .content-infos-support {
        width: 100%;
    }
}

.content-infos-support .content-item-support {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.content-infos-support .content-item-support p {
    font-size: 0.9rem;
    margin-bottom: 1px;
}