@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");

* {
    font-family: 'Lato', sans-serif !important;
    font-family: 'Manrope', sans-serif !important;
    font-family: 'Open Sans', sans-serif !important;
    font-family: 'Poppins', sans-serif !important;
    font-family: 'Prompt', sans-serif !important;
    font-family: 'Roboto', sans-serif !important;
    font-family: 'Vazirmatn', sans-serif !important;
}


.titlePage {
    margin-bottom: 50px;
}

.rowBtn {
    margin-bottom: 50px;
}

.borderColor {
    border: solid 1px #424242 !important;
}

.marginBottom {
    padding-bottom: 50px;
}

.bi-question-circle,
.bi-facebook,
.bi-people {
    font-size: 20px;
}

.form-label {
    margin-top: 10px;
}

#btnRegisterClient {
    margin-top: 20px;
}

button .easyvog-button {
    width: 97%;
    color: #fff;
    /* background-color: #0097A7; */
    background-color: transparent !important;
    border-radius: 5px;
    padding: 6px 6px;
    border: none !important;
    font-size: 20px;
    margin-top: 20px;
}


/*navbar menu*/