.loader-spinner {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

@media(max-width: 1144px) {
    .unorderedList {
        display: none;
    }
}

.unorderedList {
    background: var(--easy-secondary);
    padding: 30px 0 10px 10px;
    border-right: 4px solid var(--easy-primary);
    width: 168px;
    transition: 0.5s;
    overflow: auto;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 30;
}

/* Estilos para a barra de rolagem do menu */
.unorderedList::-webkit-scrollbar {
    width: 7px;
}

/* Estilos para o rastreador (thumb) da barra de rolagem */
.unorderedList::-webkit-scrollbar-thumb {
    background: #888;
}

/* Estilos para o fundo da barra de rolagem */
.unorderedList::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.unorderedList li {
    list-style: none;
    padding: 15px;
    color: #FFFFFF;
    font-size: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    transition: 0.5s;
    text-align: right;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.unorderedList li i {
    color: var(--easy-primary);
}

.unorderedList li:hover {
    background: var(--easy-primary);
    transition: 0.5s;
}

.unorderedList li:hover i {
    color: var(--easy-secondary);
    transition: 0.5s;
}

.unorderedList span {
    color: #FFFFFF;
}

.unorderedList li.active:before {
    content: '';
    position: absolute;
    top: -20px;
    right: 0px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 10px 10px 0 #DAA520;
}

.unorderedList li.active:after {
    content: '';
    position: absolute;
    bottom: -20px;
    right: 0px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 10px -10px 0 #DAA520;

}

.unorderedList li.active {
    background: #DAA520;
}

.unorderedList:hover {
    width: 200px
}

#icon-premium {
    margin-bottom: 5px;
}