html {
  scroll-behavior: smooth;
  background-color: #edebe9;
}

.home {
  background-color: #edebe9;
}

.icon {
  font-size: 30px;
  color: #fff;
}

a {
  text-decoration: none;
}

.logoHeader {
  background-color: #222;
  height: 100% !important;
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
}

#logoSection {
  height: 200px !important;
  /* margin-top: -70px */
  margin-top: 0px !important;
  margin-left: 150px;
}

.height {
  height: 45px
}

@media(max-width: 1144px) {
  .logo {
    display: none;
  }

  .separatorLogo {
    display: none;
  }
}

a {
  color: #fff
}

a:hover {
  color: #fff;
  text-decoration: none;
}

.logoHeader {
  background-color: #222;
  height: 200px;
  /* margin-top: 100px; */

}

.sectionProcessAcess {
  background-color: #DAA520;
  height: auto;
  margin-top: 100px;
  padding: 30px;
}

.marginTop {
  margin-top: 40px;
}

#videoimage {
  height: 250px
}

.textBold {
  font-weight: bold;
  border-bottom: solid 2px #DAA520;
}


:root {
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
}

.page-content {
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
  max-width: 1024px;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .page-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 800px) {
  .page-content {
    grid-template-columns: repeat(4, 1fr);
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  transition: transform var(--d) var(--e);
  z-index: 1;
}

.content>*+* {
  margin-top: 1rem;
}

.title {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
}

.copy {
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
}

.btn {
  cursor: pointer;
  margin-top: 1.5rem;
  /* padding: 0.75rem 1.5rem; */
  font-size: 0.65rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  /* text-transform: uppercase; */
  color: white;
  background-color: black;
  border: none;
}

.btn:hover {
  background-color: #0d0d0d;
}

.btn:focus {
  /* outline: 1px dashed yellow; */
  outline-offset: 3px;
}


.acessoProcessos {
  height: 200px;
  background-color: #DAA520;
  margin-top: 100px;

}





p {
  margin: 0;
}

.container .grid {
  display: flex;
  flex-wrap: wrap;
}

.container .btn {
  flex: 1 0 0px;
  text-align: center;
  box-sizing: border-box;
  margin: 15px 7.5px;
  padding: 10px 15px;
  background: none;
  color: #fff;
  border: 1px solid #fff;
  transition: all 0.5s;
}

.container .btn:hover {
  cursor: pointer;
}

.container .btn__effect--1 span {
  display: inline-block;
  transform: perspective(1000px) rotate3d(0, 1, 0, 0deg);
  transition: all 0.5s;
}

.container .btn__effect--1:hover span {
  transform: perspective(1000px) rotate3d(0, 1, 0, 360deg);
}

.container .btn__effect--2 {
  position: relative;
  overflow: hidden;
}

.container .btn__effect--2:before,
.container .btn__effect--2 span {
  transition: all 0.4s;
  transition-timing-function: cubic-bezier(0.85, 0, 0.2, 1);
}

.container .btn__effect--2:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  content: attr(data-text);
  transform: translate3d(0, 10px, 0);
}

.container .btn__effect--2 span {
  display: inline-block;
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

.container .btn__effect--2:hover:before {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
}

.container .btn__effect--2:hover span {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.container .btn__effect--2:hover span:nth-child(1) {
  transition-delay: 0.04s;
}

.container .btn__effect--2:hover span:nth-child(2) {
  transition-delay: 0.08s;
}

.container .btn__effect--2:hover span:nth-child(3) {
  transition-delay: 0.12s;
}

.container .btn__effect--2:hover span:nth-child(4) {
  transition-delay: 0.16s;
}

.container .btn__effect--2:hover span:nth-child(5) {
  transition-delay: 0.2s;
}

.container .btn__effect--2:hover span:nth-child(6) {
  transition-delay: 0.24s;
}

.container .btn__effect--2:hover span:nth-child(7) {
  transition-delay: 0.28s;
}

.container .btn__effect--2:hover span:nth-child(8) {
  transition-delay: 0.32s;
}

.container .btn__effect--2:hover span:nth-child(9) {
  transition-delay: 0.36s;
}

.container .btn__effect--2:hover span:nth-child(10) {
  transition-delay: 0.4s;
}

.container .btn__effect--3 {
  position: relative;
}

.container .btn__effect--3:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  content: attr(data-text);
  transform: translate3d(0, 10px, 0);
  transition: opacity 0.2s 0.25s;
}

.container .btn__effect--3 span {
  position: relative;
  overflow: hidden;
  display: block;
  width: 95%;
  margin: 0 auto;
  color: transparent;
  transition: color 0.25s ease-in-out;
}

.container .btn__effect--3 span:after {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  background: #fff;
  transform: translate3d(-100%, 0, 0);
}

.container .btn__effect--3:hover:before {
  opacity: 0;
  transition-delay: 0s;
}

.container .btn__effect--3:hover span {
  color: #fff;
  transition-delay: 0.75s;
}

.container .btn__effect--3:hover span:after {
  transform: translate3d(100%, 0, 0);
  transition: transform 0.75s cubic-bezier(0.7, 0, 0.3, 1) 0.2s;
}

.container .btn__effect--4 {
  position: relative;
}

.container .btn__effect--4 svg {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}

.container .btn__effect--4 svg polyline {
  fill: none;
  stroke: #000;
  stroke-width: 2px;
  stroke-dashoffset: 400;
  stroke-dasharray: 400;
  transition: all 1s ease-out;
}

.container .btn__effect--4 span {
  transition: 1s;
}

.container .btn__effect--4:hover polyline {
  stroke-dashoffset: 0;
}

.container .btn__effect--4:hover span {
  color: #000;
}

.container .btn__effect--5 {
  position: relative;
  overflow: hidden;
  border: 0;
  box-shadow: inset 0 0 0 1px #fff;
}

.container .btn__effect--5:before,
.container .btn__effect--5:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.container .btn__effect--5:before {
  background: #fff;
  border: 1px solid #fff;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s ease-in;
}

.container .btn__effect--5:after {
  background: #404041;
  border: 1px solid #404041;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.25s ease-out 0.29s;
}

.container .btn__effect--5 span {
  position: relative;
  z-index: 10;
}

.container .btn__effect--5:hover:before,
.container .btn__effect--5:hover:after {
  transform: translate3d(0, 0, 0);
}


.facil {
  font-size: 30px;
  color: #DAA520;
  font-weight: bold;
  text-transform: uppercase;
}



/*button*/
a {
  color: #f4f4f4;
}

.btn {
  text-decoration: none;
  border: 1px solid rgb(255, 255, 255);
  position: relative;
  overflow: hidden;
  background-color: #222;
}

.btn:hover {
  box-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 0.4);
  background-color: #222;
}

.btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent);
  transition: all 650ms;
}

.btn:hover:before {
  left: 100%;
}


/*footer*/
.footer {
  background-color: #222;
  padding: 50px;
  height: auto;
}

.fa-facebook-f,
.fa-instagram,
.fa-linkedin-in,
.fa-phone,
.fa-envelope-open,
.fa-map-marker-alt,
.fa-comment {
  color: #fff;
  font-size: 25px;
}

.mTop {
  margin-top: 20px;
}

.footer2 {
  background-color: #242932;
  padding-top: 5px;
  padding-bottom: 5px;
}

.text-footer2 {
  color: #fff;
  font-size: 12px;
}

.whatsapp-button {
  position: fixed;
  right: 15px;
  bottom: 15px;
}

.whatsapp-button2 {
  position: fixed;
  right: 100px;
  bottom: 15px;
  background-color: #404041;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ;
}

#chat {
  color: #fff;
  font-size: 30px;
}


/*Custom form bootstrap react*/
.form-control,
.form-floating>.form-select {
  background-color: transparent !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  color: #000 !important;
  outline: 0 !important;
}

.form-control:focus,
.form-floating>.form-select:focus {
  box-shadow: 0 0 0 0 !important;
  border-top: 0 none !important;
  border-left: 0 none !important;
  border-right: 0 none !important;
  outline: 0 !important;
}

.form-floating>label {
  color: #424242 !important;
}

/*End Custom form bootstrap react*/



a {
  text-decoration: none !important;
}