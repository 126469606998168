.menuActions {
    position: relative;
    display: flex;
    align-items: center;
}

.menuAction {
    display: flex;
    align-items: center;
    position: relative;
    color: var(--easy-secondary);
}

.menuAction:hover,
.menuAction:active,
.menuAction:focus {
    color: var(--easy-primary);
}

.menuAction::before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: 0px;
    left: -8px;
    border: 2px solid transparent;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
    border-bottom-left-radius: 10px;
    transition: all 0.5s ease;
}

.menuAction:hover::before {
    border-color: var(--easy-primary);
    width: 100%;
    height: 85%;
    border-top: 2px solid transparent;
    border-right: 2px solid transparent;
}

.menuAction__icon {
    font-size: 25px;
    color: #000;
    margin-right: 10px;
}

.menuAction__content {
    margin-left: 5px;
}

.menuAction__text {
    display: block;
    font-size: 14px;
    line-height: 1.15em;
    color: #000;
}

.menuAction__text {
    font-weight: 100
}

.menuActions__separator {
    display: inline-block;
    height: 28px;
    width: 1px;
    background: #9c9fa5;
    margin: 0 20px;
}

.menuAction__counter {
    position: absolute;
    font-size: 9px;
    line-height: 1em;
    font-weight: 700;
    width: 19px;
    top: 10px;
    left: 15px;
    padding: 5px 0;
    font-variant-numeric: tabular-nums;
    border-radius: 50%;
    text-align: center;
    background: var(--easy-primary);
    color: #000;
}

.form {
    display: flex;
    flex-wrap: wrap;
}

.form-item {
    position: relative;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
}

.form-item__label {
    position: absolute;
    top: 15px;
    left: 7px;
    transition: all 0.25s ease;
}

.form-item__field {
    width: 100%;
    display: inline-block;
    border-bottom: 1px solid rgba(80, 175, 193, 0.5);
    transition: border 0.25s ease;
    padding: 20px 7px 10px;
}

.form-item__field:focus {
    border-bottom: 1px solid rgba(80, 175, 193, 1)
}

.form-item.active .form-item__label {
    top: 0;
    font-size: 13px;
    opacity: 0.5;
}

.btn {
    padding: 12px 18px;
    display: block;
    font-size: 13px;
    background: #50afc1;
    color: #ffffff;
    font-weight: 600;
}

.btn--block {
    width: 100%;
}

.btn:hover {
    background: #308fa1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25), 0 5px 9px rgba(0, 0, 0, 0.125)
}

.menuLogin {
    position: absolute;
    left: -60px;
    top: calc(100% + 32px);
    z-index: 100;
    padding: 23px 26px 36px;
    width: 276px;
    border-bottom: 5px solid #50afc1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25), 0 5px 9px rgba(0, 0, 0, 0.15);
    background: #ffffff;
}

.menuLogin__actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.menuLogin__action {
    flex: 0 0 50%;
    text-align: center;
    padding: 5px 0;
    border-bottom: 1px solid transparent;
    font-weight: 300;
    font-style: italic;
    font-size: 11px;
    transition: all 0.25s ease;
}

.menuLogin__action:hover {
    color: #50afc1;
    border-bottom-color: #50afc1;
}

.bi-person {
    font-size: 20px;
}

.linkLogin {
    color: #000;
}

.ant-btn {
    box-shadow: inherit !important;
}

/* NEW SIDEBAR-RIGHT */
/* Large devices (desktops, 992px and up) */
@media (max-width: 1199.98px) {
    .sidebar-right {
        display: block;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .sidebar-right {
        display: none;
    }
}

.sidebar-right.menu {
    position: fixed;
    height: 100vh;
    top: 0px;

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin: 0px;
    padding: 0px 10px;
    padding-top: 65px;

    background: var(--easy-secondary);
    list-style-type: none;

    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transition: all 0.5s;
    overflow: auto;
}

.sidebar-right.hide {
    right: -235px;
}

.sidebar-right.open {
    right: 0px;
}

/* NEW SIDEBAR-RIGHT ICON HUMBURGUER */

.sidebar-right .menu-hamburguer {
    display: block;
    width: 30px;
    height: auto;
    cursor: pointer;
}

.sidebar-right .menu-hamburguer.checked {
    position: fixed;
    top: 23px;
    display: block;
    right: 12px;
    z-index: 9999;
}

.sidebar-right .menu-hamburguer span {
    display: block;
    width: 30px;
    height: 3px;
    margin-bottom: 5px;
    position: relative;
    cursor: pointer;

    background: var(--easy-secondary);
    border-radius: 3px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        opacity 0.55s ease;
}

.sidebar-right .menu-hamburguer.checked span {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #FFFFFF;
}

.sidebar-right .menu-hamburguer span:nth-last-child(1) {
    transform-origin: 0% 100%;
}

.sidebar-right .menu-hamburguer.checked span:nth-last-child(1) {
    opacity: 1;
    transform: rotate(-45deg) translate(0, -1px);
}

.sidebar-right .menu-hamburguer.checked span:nth-last-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

.sidebar-right .list-items {
    list-style-type: none;
    padding-left: 0px;
}

.sidebar-right .list-items li {
    padding: 10px 30px;
    color: var(--easy-primary);
    border-radius: 20px;
    margin-bottom: 10px;
}

.sidebar-right .list-items li:hover {
    background: var(--easy-primary);
    transition: 0.5s;
}

.sidebar-right .list-items li:hover i {
    color: var(--easy-secondary);
    transition: 0.5s;
}

.sidebar-right .list-items span {
    color: #FFFFFF;
}

.nav-bar-dashboard-name-user {
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .menuActions__separator {
        margin: 0 15px;
    }

    .nav-bar-dashboard-name-user {
        max-width: 50px;
    }

    .navbar-brand.dashboard .easy-title {
        font-size: 0.8rem;
    }

    .navbar-brand.dashboard .easy-title span {
        font-size: 10px;
    }
}

.title-modal-tools {
    text-align: center;
}

.title-modal-tools span {
    color: var(--easy-primary);
    font-weight: 700;
}

.subtitle-modal-tools {
    font-size: 1.2rem;
    text-align: start;
}

.content-btns-modal-tools {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.btn-modal-tools {
    display: flex;
    flex-direction: column;
}

.btn-modal-tools .modal-tools-information {
    display: flex;
    padding: 0px 0px 10px 10px;
    font-size: 0.75rem;
}

.btn-modal-tools .modal-tools-information i {
    color: #bdbdbd;
}

.btn-modal-tools .modal-tools-information p {
    margin-bottom: 0px;
}

.badge-notification {
    margin-top: -0.5rem !important;
    background-color: var(--easy-primary) !important;
    color: var(--easy-secondary) !important;
}