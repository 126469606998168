.content-infos-notification {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;

    background-color: #ffffff;
    border: 1px solid var(--easy-primary);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);

    padding: 15px;
    border-radius: 10px;
    margin-bottom: 25px;
}

.content-infos-notification.read {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.content-infos-notification .content-item-notification {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.content-infos-notification .content-item-notification p {
    font-size: 0.9rem;
    margin-bottom: 1px;
}