.table2 {
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 3px;
}

.table2>div>div {
  display: table-row;
  width: 100%;
}

.table2>div>div>div {
  display: table-cell;
  vertical-align: middle;
}

.table2>.table2_header {
  display: table-header-group;
  table-layout: fixed;
  background: #398EB9;
}

.table2>.table2_header>div>div {
  padding: 10px;
  color: #fff;
}

.table2>.table2_header>div>div:first-child {
  -webkit-border-radius: 6px 0 0 6px;
  -moz-border-radius: 6px 0 0 6px;
  border-radius: 6px 0 0 6px;
}

.table2>.table2_header>div>div:last-child {
  -webkit-border-radius: 0 6px 6px 0;
  -moz-border-radius: 0 6px 6px 0;
  border-radius: 0 6px 6px 0;
}

.table2>.table2_body {
  display: table-row-group;
}

.table2>.table2_body>div:hover {
  position: relative;
  z-index: 5 !important;
}

.table2>.table2_body>div:hover>div {
  -webkit-box-shadow: inset 0 0 500px 200px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: inset 0 0 500px 200px rgba(0, 0, 0, 0.03);
  box-shadow: inset 0 0 500px 200px rgba(0, 0, 0, 0.03);
}

.table2>.table2_body>div>div {
  padding: 5px 10px;
  background: #EFF4F6;
}

.table2>.table2_body>div>div:first-child {
  -webkit-border-radius: 6px 0 0 6px;
  -moz-border-radius: 6px 0 0 6px;
  border-radius: 6px 0 0 6px;
}

.table2>.table2_body>div>div:last-child {
  -webkit-border-radius: 0 6px 6px 0;
  -moz-border-radius: 0 6px 6px 0;
  border-radius: 0 6px 6px 0;
}

.table2>.table2_body>div>div>div {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.table2>.table2_body>div.better_position>div {
  background: #398EB9;
  color: #fff;
}

.table2.resp1>.table2_header {
  display: none !important;
}

.table2.resp1>.table2_body>div>div {
  vertical-align: top;
}

.table2.resp1>.table2_body>div>div::before {
  content: attr(data-thn) ":";
  display: block;
  width: 100%;
  opacity: 0.5;
  font-size: 13px;
}

.table2.resp2 {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.table2.resp2>div>div {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  margin-bottom: 3px;
}

.table2.resp2>.table2_body>div>div {
  display: block;
  /* width: 50%; */
}

.btnW {
  width: 110px !important;
  font-size: 14px !important;
  margin-left: 5px !important;
}

.colorBlack {
  color: #263238 !important;
  margin-top: -20px;
}

.color {
  color: #263238 !important;
}

.btnDelete {
  background-color: #C2185B !important;
  float: right !important;
}

.btnDelete:hover {
  background-color: #D81B60 !important;
}

/* @media(max-width: 700px){
  .btnDelete{
    float: left !important;
  }
} */
.btnExport2 {
  background-color: #616161 !important;
}

.btnExport {
  background-color: #616161 !important;
  margin-top: -5px;
}

.btnExport:hover {
  background-color: #9E9E9E !important;
}

.btnUpdate {
  background-color: #0D47A1 !important;
}

.btnUpdate:hover {
  background-color: #1565C0 !important;
}

#width {
  /* border: solid red 1px; */
  /* width: 340px !important; */
}

.textBlack {
  color: #263238 !important;
}

/* checkbox */

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
  border-radius: 5px;
}

.form-group label {
  position: relative;
  cursor: pointer;
}

.form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #424242;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.modalGradient {
  background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(236, 179, 33, 1) 0%, rgba(237, 235, 233, 1) 90%);
}

.tableHeaderGradient {
  background-image: radial-gradient(circle farthest-corner at 10% 20%, rgba(236, 179, 33, 1) 0%, rgba(237, 235, 233, 1) 90%);
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.accordion-item {
  background: transparent !important;
}

.accordion-header>.collapsed {
  width: 95% !important;
  height: 32px !important;
  border-radius: 24px !important;
  margin: 16px !important;
}

.accordion-button:focus {
  border-color: inherit !important;
  outline: 0 none !important;
}

.accordion-button:not(.collapsed) {
  color: none !important;
}

.accordion-button:not(.collapsed):focus {
  color: inherit !important;
  outline: 0 none !important;
}