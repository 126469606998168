.easyvog-navbar {
    position: relative;
    height: 4.375rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    background-color: #ffffff;
    box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
    z-index: 10;
}

.easyvog-navbar .easyvog-nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.easyvog-navbar .easyvog-nav div.logo {
    float: left;
    width: auto;
    height: auto;
    top: 0;
    padding-left: 3rem;
    padding-top: 1rem;
}

.easyvog-navbar .easyvog-nav div.logo a {
    text-decoration: none;
    color: #FFFFFF;
}

.easyvog-navbar .easyvog-nav div.logo a:hover {
    color: #00E676;
}

.easyvog-navbar .navTrigger {
    display: none;
}