.btnCustomCalendar {
  width: 100%;
  max-width: 370px;
  height: 100px;
  color: #fff;
  background-color: #0097A7;
  border-radius: 5px;
  border: none !important;
  font-size: 20px;
}

.btnCustomCalendar:hover {
  background-color: #27b4c4;
}


/*accordeon*/
.accordion-button:focus {
  border-color: inherit !important;
  outline: 0 none !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
  /* border-color: rgba(0, 0, 0, .125) !important; */
}

/*card*/

.borderCard {
  /* border: solid #424242 1px; */
  height: 200px;
}