.content-list-notifications {
    background-color: #ffffff;
    border: 1px solid var(--easy-primary);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);

    padding: 15px;
    border-radius: 10px;
    margin-bottom: 13px;
    color: #4f4f4f !important;
}

.content-list-notifications.read {
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.content-list-notifications h5 {
    font-weight: 600;
    color: var(--easy-secondary);
    margin-bottom: 15px;
}

.content-list-notifications p {
    color: #4f4f4f !important;
}

.content-list-notifications p {
    margin-bottom: 2px;
}

.content-list-notifications .footer-actions {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    font-size: 0.85rem;
}

.delete-notification {
    margin-left: 10px;
    cursor: pointer;
    z-index: 222;
    color: #d14444;
}