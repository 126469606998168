:root {
    --easy-primary: #DAA520;
    --easy-secondary: #222222;
    --easy-disabled: #cccccc;
    --easy-error: #be2222;
}

body {
    min-width: 374px;
    min-height: 100vh;
}

@media (min-width: 768px) {
    nav[class*="navbar"] {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}

@media only screen and (max-width: 480px) {
    .Toastify__toast-container {
        width: 90%;
        left: 5%;
    }
}

.text-ellipsis {
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.btn-bg-primary {
    background-color: var(--easy-primary) !important;
}

.btn-bg-primary.custom-disabled {
    background-color: var(--easy-disabled) !important;
    opacity: 0.7;
    border: 1px solid rgb(163, 160, 160) !important;
}