.section-header {
    display: block;
}

.section-header nav {
    position: fixed;
    width: 100%;
    z-index: 999;
    min-width: 374px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    background-color: #edebe9;
}

.easy-title {
    color: var(--easy-primary);
    font-size: 1.3rem;
}

.vog {
    color: #000000;
    font-weight: bolder;
    font-size: 20px;
}

.content-items-navbar {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

button.wh-ap-btn {
    outline: none;
    width: 34px;
    height: 34px;
    border: 0;
    background-color: #25D366;
    padding: 0;
    border-radius: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24);
    cursor: pointer;
    transition: opacity 0.3s, background 0.3s, box-shadow 0.3s;
}

button.wh-ap-btn::after {
    content: '';
    background-image: url(//i.imgur.com/cAS6qqn.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 48%;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 1;
}

button.wh-ap-btn:hover {
    opacity: 1;
    background-color: #01E675;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16), 0 3px 6px rgba(0, 0, 0, .23);
}


@media screen and (min-width: 768px) and (max-width: 1024px) {
    .container {
        margin: 0;
    }
}

.affixx {
    padding: 0;
    background-color: var(--easy-secondary) !important;
}

.bi-person,
.bi-bell,
.bi-box-arrow-right {
    color: var(--easy-secondary);
}