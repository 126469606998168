/* component: ConversationsList */
.conversation-user {
    min-width: 0;
    word-wrap: break-word;
    background-color: #fefefe;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.1);

    padding: 5px 10px;
    margin-bottom: 5px;
    transition: opacity .15s linear;
    border-radius: 3px;
}

.conversation-user.active {
    margin-left: 10px;
    margin-right: -8px;
    background-color: #f1f1f1;
    transition: .5s;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    border-right: 4px solid var(--easy-primary);
}

/* component: ChatBox */
.container-chat {
    min-height: 500px;
    max-height: 500px;
    overflow-y: scroll;
}

.container-chat-message {
    display: flex;
    margin-bottom: 10px;
}

.container-chat-message.message-send-me {
    align-items: flex-start;
    justify-content: flex-end;
    margin-right: 10px;
}

.content-chat-message {
    padding: 5px 10px;
    border-radius: 5px;
    max-width: 70%;
}

.content-chat-message.message-send-me {
    background-color: #d9fdd3;
}

.content-chat-message.message-send-other {
    background-color: #f1f1f1;
}

.chat-message .message {
    font-size: 1rem;
    margin-bottom: 2px;
}

.chat-message .date {
    font-size: 0.75rem;
    text-align: right;
}