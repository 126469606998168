.container-favorites {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 12px;
}

.container-favorites .card .card-header {
    text-align: center;
}

.container-favorites .card {
    text-align: left;
}

.container-favorites .container-empty {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 70px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .container-favorites {
        display: block;
    }

    .container-favorites .card {
        margin-bottom: 10px;
    }

    .container-favorites .container-empty {
        margin-left: 0px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 1199.98px) {
    .container-favorites {
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1200px) {
    .container-favorites {
        justify-content: flex-start;
        margin-left: 70px;
    }
}