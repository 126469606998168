/* .home {
    height: 120vw;
} */
.icon {
  font-size: 30px;
  color: #fff;
}

.Header {
  background-color: #222;
  height: 300px !important;
}

#logoSection {
  height: 200px !important;
  /* margin-top: -70px */
  margin-top: 0px !important;
}

/* .height {
  height: 100px
} */

a {
  color: #fff
}

a:hover {
  color: #fff;
  text-decoration: none;
}

.logoHeader {
  background-color: #222;
  height: 200px;
  margin-top: 100px;
}

.section-process-acess {
  background-color: #DAA520;
  height: auto;
  margin-top: 50px;
  padding: 30px;
}

.marginTop {
  margin-top: 40px;
}

#videoimage {
  height: 250px
}

.textBold {
  font-weight: bold;
  border-bottom: solid 2px #DAA520;
}


:root {
  --d: 700ms;
  --e: cubic-bezier(0.19, 1, 0.22, 1);
}

.page-content {
  display: grid;
  grid-gap: 1rem;
  padding: 1rem;
  max-width: 1024px;
  margin: 0 auto;
}

@media (min-width: 600px) {
  .page-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 800px) {
  .page-content {
    grid-template-columns: repeat(4, 1fr);
  }
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  transition: transform var(--d) var(--e);
  z-index: 1;
}

.content>*+* {
  margin-top: 1rem;
}

.title {
  font-size: 1.3rem;
  font-weight: bold;
  line-height: 1.2;
}

.copy {
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.35;
}

.btn {
  cursor: pointer;
  margin-top: 1.5rem;
  /* padding: 0.75rem 1.5rem; */
  font-size: 0.65rem;
  font-weight: bold;
  letter-spacing: 0.025rem;
  /* text-transform: uppercase; */
  color: white;
  background-color: black;
  border: none;
}

.btn:hover {
  background-color: #0d0d0d;
}

.btn:focus {
  /* outline: 1px dashed yellow; */
  outline-offset: 3px;
}

.acessoProcessos {
  height: 200px;
  background-color: #DAA520;
  margin-top: 100px;

}





p {
  margin: 0;
}

.container .grid {
  display: flex;
  flex-wrap: wrap;
}

.container .btn {
  flex: 1 0 0px;
  /* font: 20px/1 "Nunito", sans-serif; */
  text-align: center;
  box-sizing: border-box;
  margin: 15px 7.5px;
  padding: 10px 15px;
  background: none;
  color: #fff;
  border: 1px solid #fff;
  transition: all 0.5s;
}

.container .btn:hover {
  cursor: pointer;
  background-color: var(--easy-secondary);
}

.container .btn__effect--1 span {
  display: inline-block;
  transform: perspective(1000px) rotate3d(0, 1, 0, 0deg);
  transition: all 0.5s;
}

.container .btn__effect--1:hover span {
  transform: perspective(1000px) rotate3d(0, 1, 0, 360deg);
}

.container .btn__effect--2 {
  position: relative;
  overflow: hidden;
}

.container .btn__effect--2:before,
.container .btn__effect--2 span {
  transition: all 0.4s;
  transition-timing-function: cubic-bezier(0.85, 0, 0.2, 1);
}

.container .btn__effect--2:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  content: attr(data-text);
  transform: translate3d(0, 10px, 0);
}

.container .btn__effect--2 span {
  display: inline-block;
  opacity: 0;
  transform: translate3d(0, -10px, 0);
}

.container .btn__effect--2:hover:before {
  opacity: 0;
  transform: translate3d(0, 40px, 0);
}

.container .btn__effect--2:hover span {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.container .btn__effect--2:hover span:nth-child(1) {
  transition-delay: 0.04s;
}

.container .btn__effect--2:hover span:nth-child(2) {
  transition-delay: 0.08s;
}

.container .btn__effect--2:hover span:nth-child(3) {
  transition-delay: 0.12s;
}

.container .btn__effect--2:hover span:nth-child(4) {
  transition-delay: 0.16s;
}

.container .btn__effect--2:hover span:nth-child(5) {
  transition-delay: 0.2s;
}

.container .btn__effect--2:hover span:nth-child(6) {
  transition-delay: 0.24s;
}

.container .btn__effect--2:hover span:nth-child(7) {
  transition-delay: 0.28s;
}

.container .btn__effect--2:hover span:nth-child(8) {
  transition-delay: 0.32s;
}

.container .btn__effect--2:hover span:nth-child(9) {
  transition-delay: 0.36s;
}

.container .btn__effect--2:hover span:nth-child(10) {
  transition-delay: 0.4s;
}

.container .btn__effect--3 {
  position: relative;
}

.container .btn__effect--3:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  content: attr(data-text);
  transform: translate3d(0, 10px, 0);
  transition: opacity 0.2s 0.25s;
}

.container .btn__effect--3 span {
  position: relative;
  overflow: hidden;
  display: block;
  width: 95%;
  margin: 0 auto;
  color: transparent;
  transition: color 0.25s ease-in-out;
}

.container .btn__effect--3 span:after {
  position: absolute;
  top: 50%;
  left: 0;
  content: "";
  width: 100%;
  height: 2px;
  background: #fff;
  transform: translate3d(-100%, 0, 0);
}

.container .btn__effect--3:hover:before {
  opacity: 0;
  transition-delay: 0s;
}

.container .btn__effect--3:hover span {
  color: #fff;
  transition-delay: 0.75s;
}

.container .btn__effect--3:hover span:after {
  transform: translate3d(100%, 0, 0);
  transition: transform 0.75s cubic-bezier(0.7, 0, 0.3, 1) 0.2s;
}

.container .btn__effect--4 {
  position: relative;
}

.container .btn__effect--4 svg {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
}

.container .btn__effect--4 svg polyline {
  fill: none;
  stroke: #000;
  stroke-width: 2px;
  stroke-dashoffset: 400;
  stroke-dasharray: 400;
  transition: all 1s ease-out;
}

.container .btn__effect--4 span {
  transition: 1s;
}

.container .btn__effect--4:hover polyline {
  stroke-dashoffset: 0;
}

.container .btn__effect--4:hover span {
  color: #000;
}

.container .btn__effect--5 {
  position: relative;
  overflow: hidden;
  border: 0;
  box-shadow: inset 0 0 0 1px #fff;
}

.container .btn__effect--5:before,
.container .btn__effect--5:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: "";
}

.container .btn__effect--5:before {
  background: #fff;
  border: 1px solid #fff;
  transform: translate3d(-100%, 0, 0);
  transition: transform 0.3s ease-in;
}

.container .btn__effect--5:after {
  background: #404041;
  border: 1px solid #404041;
  transform: translate3d(100%, 0, 0);
  transition: transform 0.25s ease-out 0.29s;
}

.container .btn__effect--5 span {
  position: relative;
  z-index: 10;
}

.container .btn__effect--5:hover:before,
.container .btn__effect--5:hover:after {
  transform: translate3d(0, 0, 0);
}


.facil {
  font-size: 30px;
  color: #DAA520;
  font-weight: bold;
  text-transform: uppercase;
}



/*button*/
a {
  color: #f4f4f4;
}

.btn {
  text-decoration: none;
  border: 1px solid rgb(255, 255, 255);
  position: relative;
  overflow: hidden;
  background-color: #222;
}

.btn:hover {
  box-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 0.4);
  background-color: #222;
}

.btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent);
  transition: all 650ms;
}

.btn:hover:before {
  left: 100%;
}


/*footer*/
.footer {
  background-color: #222;
  padding: 50px;
  height: auto;
}

.fa-facebook-f,
.fa-instagram,
.fa-linkedin-in,
.fa-phone,
.fa-envelope-open,
.fa-map-marker-alt,
.fa-comment {
  color: #fff;
  font-size: 25px;
}

.mTop {
  margin-top: 20px;
}

.footer2 {
  background-color: #242932;
  padding-top: 5px;
  padding-bottom: 5px;
}

.text-footer2 {
  color: #fff;
  font-size: 12px;
}

.whatsapp-button {
  position: fixed;
  right: 15px;
  bottom: 15px;
}

.whatsapp-button2 {
  position: fixed;
  right: 100px;
  bottom: 15px;
  background-color: #404041;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  ;
}

#chat {
  color: #fff;
  font-size: 30px;
}

/*chat*/
@media (min-width: 450px) {
  .main-card {
    width: 96%;
    max-width: 400px;
    height: calc(100% - 32px) !important;
    border-radius: 8px !important;
    max-height: 600px;
    margin: 16px !important;
  }
}

.collapsed {
  width: 48px !important;
  height: 48px !important;
  border-radius: 24px !important;
  margin: 16px !important;
}

#chatbot_toggle {
  position: absolute;
  right: 0;
  border: none;
  height: 48px;
  width: 48px;
  background: rebeccapurple;
  padding: 14px;
  color: white;
}

#chatbot_toggle:hover {
  background: #7d56a5;
}

.line {
  height: 1px;
  background-color: rebeccapurple;
  width: 100%;
  opacity: 0.2;
}

.main-title {
  background-color: rebeccapurple;
  font-size: large;
  font-weight: bold;
  display: flex;
  height: 48px;
}

.main-title>div {
  height: 48px;
  width: 48px;
  display: flex;
  margin-left: 8px;
}

.main-title svg {
  height: 24px;
  margin: auto;
}

.main-title>span {
  margin: auto auto auto 8px;
}

.chat-area {
  flex-grow: 1;
  overflow: auto;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.input-message {
  padding: 8px 48px 8px 16px;
  flex-grow: 1;
  border: none;
}

.input-message:focus {
  outline: none;
}

.input-div {
  height: 48px;
  display: flex;
}

.input-send {
  background: transparent;
  width: 48px;
  height: 48px;
  right: 0%;
  border: none;
  cursor: pointer;
}

.input-send:hover {
  background: lavender;
}

.input-send svg {
  fill: rebeccapurple;
  margin: 11px 8px;
}

.chat-message-div {
  display: flex;
}

.chat-message-sent {
  background-color: white;
  margin: 8px 16px 8px 64px;
  padding: 8px 16px;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 100ms;
  color: black;
  border-radius: 8px 8px 2px 8px;
  background-color: lavender;
}

.chat-message-received {
  background-color: white;
  margin: 8px 64px 8px 16px;
  padding: 8px 16px;
  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 100ms;
  color: black;
  border-radius: 8px 8px 8px 2px;
  background-color: lavender;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}




@media(max-width: 1111px) {
  .easyText {
    display: none;
  }
}

@media(max-width: 665px) {
  #logoSection {
    height: 200px !important;
    margin-top: 0 !important;
  }
}

.aboutMore::before {
  content: "Saiba mais sobre a EasyVog";
}

@media(max-width: 1280px) {
  .aboutMore::before {
    content: "Saiba mais";
  }
}

@media(max-width: 992px) {
  .aboutMore {
    margin-bottom: 30px;
  }

  #video {
    margin-right: 15px;
  }
}

#logoFooter {
  width: 100%;
  height: auto;
  max-height: 200px;
}

.videoContainer {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}

@media(max-width: 980px) {
  .videoContainer {
    display: flex;
    flex-wrap: wrap-reverse;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}


/*floating button*/

.fab-wrapper {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
}

.fab-checkbox {
  display: none;
}

.fab {
  position: absolute;
  bottom: -1rem;
  right: -1rem;
  width: 4rem;
  height: 4rem;
  background: #DAA520;
  border-radius: 50%;
  background: #DAA520;
  box-shadow: 0px 5px 20px #424242b0;
  transition: all 0.3s ease;
  z-index: 1;
  border-bottom-right-radius: 6px;
  border: 1px solid #242932;
}

.fab:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}

.fab-checkbox:checked~.fab:before {
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  background-color: rgba(255, 255, 255, 0.2);
}

.fab:hover {
  background: #ecb321;
  box-shadow: 0px 5px 20px 5px #42424296;
}

.fab-dots {
  position: absolute;
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  transform: translateX(0%) translateY(-50%) rotate(0deg);
  opacity: 1;
  animation: blink 3s ease infinite;
  transition: all 0.3s ease;
}

.fab-dots-1 {
  left: 15px;
  animation-delay: 0s;
}

.fab-dots-2 {
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation-delay: 0.4s;
}

.fab-dots-3 {
  right: 15px;
  animation-delay: 0.8s;
}

.fab-checkbox:checked~.fab .fab-dots {
  height: 6px;
}

.fab .fab-dots-2 {
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
}

.fab-checkbox:checked~.fab .fab-dots-1 {
  width: 32px;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.fab-checkbox:checked~.fab .fab-dots-3 {
  width: 32px;
  border-radius: 10px;
  right: 50%;
  transform: translateX(50%) translateY(-50%) rotate(-45deg);
}

@keyframes blink {
  50% {
    opacity: 0.25;
  }
}

.fab-checkbox:checked~.fab .fab-dots {
  animation: none;
}

.fab-wheel {
  position: absolute;
  bottom: 0;
  right: 0;
  /* border: 1px solid ; */
  width: 10rem;
  height: 10rem;
  transition: all 0.3s ease;
  transform-origin: bottom right;
  transform: scale(0);
}

.fab-checkbox:checked~.fab-wheel {
  transform: scale(1);
}

.fab-action {
  position: absolute;
  background: #0f1941;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: White;
  box-shadow: 0 0.1rem 1rem #424242c0;
  transition: all 1s ease;

  opacity: 0;
}

.fab-checkbox:checked~.fab-wheel .fab-action {
  opacity: 1;
}

.fab-action:hover {
  background-color: #f16100;
}

.fab-wheel .fab-action-1 {
  right: -1rem;
  top: 0;
}

.fab-wheel .fab-action-2 {
  right: 3.4rem;
  top: 0.5rem;
}

.fab-wheel .fab-action-3 {
  left: 0.5rem;
  bottom: 3.4rem;
}

.fab-wheel .fab-action-4 {
  left: 0;
  bottom: -1rem;
}